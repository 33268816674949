<template>
  <div class="links-button">
    <a href="javascript:void(0)" data-cb-type="portal"
      ><button class="secondary-btn-outline">
        Download Invoices
      </button></a
    >
    <a href="javascript:void(0)" data-cb-type="portal"
      ><button class="secondary-btn-outline">
        Upgrade Downgrade
      </button></a
    >
    <router-link :to="{ name: 'subscription' }"
      ><button class="secondary-btn-outline">
        Pause or Cancel
      </button></router-link
    >
    <a href="javascript:void(0)" data-cb-type="portal"
      ><button class="secondary-btn-outline">
        Manage credit cards
      </button></a
    >
  </div>
</template>

<script>
export default {
  name: "mangeBilling",
  data() {
    return {};
  },
  created() {
    let externalScript = document.createElement("script");
    externalScript.onload = () => {
      window.Chargebee.init({});
      window.Chargebee.registerAgain();
    };
    externalScript.setAttribute(
      "src",
      "https://js.chargebee.com/v2/chargebee.js"
    );
    document.head.appendChild(externalScript);
  },
  methods: {},
  beforeMount() {
    this.$eventBus.$emit("callCreditMethod");
  },
  mounted() {},
  computed: {},
};
</script>

<style>
.links-button a {
  display: block;
  width: 100%;
  max-width: 200px;
}
.links-button {
  text-align: center;
  padding: 0;
  height: 79vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}
.links-button a button {
  margin: 0;
  width: 100%;
}
</style>
